exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-business-user-tsx": () => import("./../../../src/pages/business-user.tsx" /* webpackChunkName: "component---src-pages-business-user-tsx" */),
  "component---src-pages-business-users-tsx": () => import("./../../../src/pages/business-users.tsx" /* webpackChunkName: "component---src-pages-business-users-tsx" */),
  "component---src-pages-cache-management-tsx": () => import("./../../../src/pages/cache-management.tsx" /* webpackChunkName: "component---src-pages-cache-management-tsx" */),
  "component---src-pages-campaign-add-tsx": () => import("./../../../src/pages/campaign/add.tsx" /* webpackChunkName: "component---src-pages-campaign-add-tsx" */),
  "component---src-pages-campaign-index-tsx": () => import("./../../../src/pages/campaign/index.tsx" /* webpackChunkName: "component---src-pages-campaign-index-tsx" */),
  "component---src-pages-campaigns-tsx": () => import("./../../../src/pages/campaigns.tsx" /* webpackChunkName: "component---src-pages-campaigns-tsx" */),
  "component---src-pages-companies-tsx": () => import("./../../../src/pages/companies.tsx" /* webpackChunkName: "component---src-pages-companies-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-customer-reports-tsx": () => import("./../../../src/pages/customer-reports.tsx" /* webpackChunkName: "component---src-pages-customer-reports-tsx" */),
  "component---src-pages-failed-logins-tsx": () => import("./../../../src/pages/failed-logins.tsx" /* webpackChunkName: "component---src-pages-failed-logins-tsx" */),
  "component---src-pages-failed-purchases-tsx": () => import("./../../../src/pages/failed-purchases.tsx" /* webpackChunkName: "component---src-pages-failed-purchases-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-item-suggestions-tsx": () => import("./../../../src/pages/item-suggestions.tsx" /* webpackChunkName: "component---src-pages-item-suggestions-tsx" */),
  "component---src-pages-karma-basic-item-add-tsx": () => import("./../../../src/pages/karma-basic-item/add.tsx" /* webpackChunkName: "component---src-pages-karma-basic-item-add-tsx" */),
  "component---src-pages-karma-basic-item-index-tsx": () => import("./../../../src/pages/karma-basic-item/index.tsx" /* webpackChunkName: "component---src-pages-karma-basic-item-index-tsx" */),
  "component---src-pages-karma-basic-items-tsx": () => import("./../../../src/pages/karma-basic-items.tsx" /* webpackChunkName: "component---src-pages-karma-basic-items-tsx" */),
  "component---src-pages-karmeleon-tsx": () => import("./../../../src/pages/karmeleon.tsx" /* webpackChunkName: "component---src-pages-karmeleon-tsx" */),
  "component---src-pages-karmeleons-tsx": () => import("./../../../src/pages/karmeleons.tsx" /* webpackChunkName: "component---src-pages-karmeleons-tsx" */),
  "component---src-pages-kitchen-dispatch-tsx": () => import("./../../../src/pages/kitchen-dispatch.tsx" /* webpackChunkName: "component---src-pages-kitchen-dispatch-tsx" */),
  "component---src-pages-links-tsx": () => import("./../../../src/pages/links.tsx" /* webpackChunkName: "component---src-pages-links-tsx" */),
  "component---src-pages-location-tsx": () => import("./../../../src/pages/location.tsx" /* webpackChunkName: "component---src-pages-location-tsx" */),
  "component---src-pages-locations-create-tsx": () => import("./../../../src/pages/locations-create.tsx" /* webpackChunkName: "component---src-pages-locations-create-tsx" */),
  "component---src-pages-locations-tsx": () => import("./../../../src/pages/locations.tsx" /* webpackChunkName: "component---src-pages-locations-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-loyalty-programs-tsx": () => import("./../../../src/pages/loyalty-programs.tsx" /* webpackChunkName: "component---src-pages-loyalty-programs-tsx" */),
  "component---src-pages-me-tsx": () => import("./../../../src/pages/me.tsx" /* webpackChunkName: "component---src-pages-me-tsx" */),
  "component---src-pages-on-call-tsx": () => import("./../../../src/pages/on-call.tsx" /* webpackChunkName: "component---src-pages-on-call-tsx" */),
  "component---src-pages-payment-tsx": () => import("./../../../src/pages/payment.tsx" /* webpackChunkName: "component---src-pages-payment-tsx" */),
  "component---src-pages-payments-tsx": () => import("./../../../src/pages/payments.tsx" /* webpackChunkName: "component---src-pages-payments-tsx" */),
  "component---src-pages-payout-account-tsx": () => import("./../../../src/pages/payout-account.tsx" /* webpackChunkName: "component---src-pages-payout-account-tsx" */),
  "component---src-pages-permissions-tsx": () => import("./../../../src/pages/permissions.tsx" /* webpackChunkName: "component---src-pages-permissions-tsx" */),
  "component---src-pages-purchase-tsx": () => import("./../../../src/pages/purchase.tsx" /* webpackChunkName: "component---src-pages-purchase-tsx" */),
  "component---src-pages-purchases-tsx": () => import("./../../../src/pages/purchases.tsx" /* webpackChunkName: "component---src-pages-purchases-tsx" */),
  "component---src-pages-sale-reminders-edit-tsx": () => import("./../../../src/pages/sale-reminders/edit.tsx" /* webpackChunkName: "component---src-pages-sale-reminders-edit-tsx" */),
  "component---src-pages-sale-reminders-index-tsx": () => import("./../../../src/pages/sale-reminders/index.tsx" /* webpackChunkName: "component---src-pages-sale-reminders-index-tsx" */),
  "component---src-pages-sale-reminders-new-tsx": () => import("./../../../src/pages/sale-reminders/new.tsx" /* webpackChunkName: "component---src-pages-sale-reminders-new-tsx" */),
  "component---src-pages-system-notice-[id]-tsx": () => import("./../../../src/pages/system-notice/[id].tsx" /* webpackChunkName: "component---src-pages-system-notice-[id]-tsx" */),
  "component---src-pages-system-notice-create-tsx": () => import("./../../../src/pages/system-notice/create.tsx" /* webpackChunkName: "component---src-pages-system-notice-create-tsx" */),
  "component---src-pages-system-notice-index-tsx": () => import("./../../../src/pages/system-notice/index.tsx" /* webpackChunkName: "component---src-pages-system-notice-index-tsx" */),
  "component---src-pages-tab-settlements-tsx": () => import("./../../../src/pages/tab-settlements.tsx" /* webpackChunkName: "component---src-pages-tab-settlements-tsx" */),
  "component---src-pages-tabs-tsx": () => import("./../../../src/pages/tabs.tsx" /* webpackChunkName: "component---src-pages-tabs-tsx" */),
  "component---src-pages-tag-create-tsx": () => import("./../../../src/pages/tag-create.tsx" /* webpackChunkName: "component---src-pages-tag-create-tsx" */),
  "component---src-pages-tag-filter-tsx": () => import("./../../../src/pages/tag-filter.tsx" /* webpackChunkName: "component---src-pages-tag-filter-tsx" */),
  "component---src-pages-tag-type-create-tsx": () => import("./../../../src/pages/tag-type-create.tsx" /* webpackChunkName: "component---src-pages-tag-type-create-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-pages-uploads-tsx": () => import("./../../../src/pages/uploads.tsx" /* webpackChunkName: "component---src-pages-uploads-tsx" */),
  "component---src-pages-user-search-tsx": () => import("./../../../src/pages/user-search.tsx" /* webpackChunkName: "component---src-pages-user-search-tsx" */),
  "component---src-pages-user-tsx": () => import("./../../../src/pages/user.tsx" /* webpackChunkName: "component---src-pages-user-tsx" */)
}

